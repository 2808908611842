import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import template from './iphone-template.png';
import en from './en.PNG'

const useStyles = makeStyles({
  screenshotContainer: {
    width: 280,
    height: 500,
    margin: 20,
    flex: 'none'
  },
  screenshotBackground: {
    position: 'relative',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${template})`
  },
  image: {
    position: 'absolute',
    left: 32,
    right: 32,
    top: 60, 
    height: 378
  },
});

function Screenshot() {
  const classes = useStyles();

  return (<Box className={classes.screenshotContainer}>
    <Box className={classes.screenshotBackground} src={template}>
      <img className={classes.image} src={en} alt=""/>
    </Box>
  </Box>);
}

export default Screenshot;