import { useCallback } from 'react';
import { IconButton, Box, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Email from 'mdi-react/EmailIcon';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        flexGrow: 1,
        width: '100%',
        maxWidth: theme.maxWidth,
        margin: 'auto',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    spase: {
        flexGrow: 1
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    link: {
        color: 'blue',
        padding: 8
    },
    iconBackground: {
        width: 48,
        height: 48
    },
    icon: {
        color: theme.palette.primary.main,
    }
}));

function Footer() {
    const classes = useStyles();
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        window.open('mailto:support@budgethelper.app?subject=Budget%20helper', '_blank');
    }, []);

    return (
        <Box className={classes.footerContainer}>
            <Box className={classes.spase} />
            <IconButton className={classes.iconBackground} onClick={onClick}>
                <Email className={classes.icon} />
            </IconButton>
            <Box className={classes.linksContainer}>
                <Link to="/disclosure">
                    <Typography className={classes.link} variant="h6" noWrap>
                        {t('disclosure')}
                    </Typography>
                </Link>
                <Link to="/privacy">
                    <Typography className={classes.link} variant="h6" noWrap>
                        {t('privacyPolicy')}
                    </Typography>
                </Link>
                <Link to="/terms">
                    <Typography className={classes.link} variant="h6" noWrap>
                        {t('termsOfUse')}
                    </Typography>
                </Link>
            </Box>
            <Typography variant="body1" noWrap>© 2020 Budget helper</Typography>
        </Box>
    );
}
export default Footer;