import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Info from "./screens/info";
import Privacy from "./screens/privacy";
import Terms from "./screens/terms";
import Disclosure from "./screens/disclosure";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/disclosure">
          <Disclosure />
        </Route>
        <Route path="/">
          <Info />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
