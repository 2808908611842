import { useCallback } from 'react'
import { Button, makeStyles } from '@material-ui/core';
import playstore from './playstore.png';
import appstore from './appstore.png';

const useStyles = makeStyles({
    button: {
        height: 75,
        width: props => props.width,
        borderRadius: 10,
        backgroundImage: props => `url(${props.image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
    }
});

function LinkButton({ link, ...props }) {
    const classes = useStyles(props);

    const onClick = useCallback(() => {
        window.open(link, '_blank');
    }, [link]);

    return (<Button className={classes.button} onClick={onClick} />);
}

export function Playstore() {
    return (<LinkButton image={playstore} width={255} link="https://play.google.com/store/apps/details?id=app.budgethelper" />);
}

export function Appstore() {
    return (<LinkButton image={appstore}  width={220} link="https://apps.apple.com/us/app/budget-helper-spreadsheets/id1535156958" />);
}
