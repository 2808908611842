import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Spreadsheet from 'mdi-react/GoogleSpreadsheetIcon';
import Users from 'mdi-react/AccountGroupIcon';
import Chart from 'mdi-react/PollIcon';
import Categories from 'mdi-react/ViewGridPlusIcon';
import Budgets from 'mdi-react/ChartBarStackedIcon';
import Accounts from 'mdi-react/CreditCardMultipleIcon';
import Files from 'mdi-react/FileTableBoxMultipleIcon';
import Notifications from 'mdi-react/BellRingIcon';
import Currencies from 'mdi-react/CurrencyUsdIcon';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        maxWidth: theme.maxWidth,
        margin: 'auto',
        padding: 24
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),

    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: theme.palette.text.primary,
    },
    subtitle: {
        flexGrow: 1,
        color: theme.palette.text.secondary,
    },
    iconBackground: {
        width: 36,
        height: 36,
        display: 'flex',
        borderRadius: '50%'
    },
    icon: {
        width: 24,
        height: 24,
        margin: 'auto',
        color: theme.palette.primary.main,
    }
}));

const features = (t) => [
    {
        icon: Spreadsheet,
        title: t('featuresSpreadsheetsTitle'),
        subtitle: t('featuresSpreadsheetsSubtitle')
    },
    {
        icon: Users,
        title: t('featuresUsersTitle'),
        subtitle: t('featuresUsersSubtitle')
    },
    {
        icon: Chart,
        title: t('featuresReportsTitle'),
        subtitle: t('featuresReportsSubtitle')
    },
    {
        icon: Accounts,
        title: t('featuresAccountsTitle'),
        subtitle: t('featuresAccountsSubtitle')
    },
    {
        icon: Budgets,
        title: t('featuresBudgetsTitle'),
        subtitle: t('featuresBudgetsSubtitle')
    },
    {
        icon: Currencies,
        title: t('featuresCurrenciesTitle'),
        subtitle: t('featuresCurrenciesSubtitle')
    },
    {
        icon: Categories,
        title: t('featuresCategoriesTitle'),
        subtitle: t('featuresCategoriesSubtitle')
    },
    {
        icon: Notifications,
        title: t('featuresNotificationsTitle'),
        subtitle: t('featuresNotificationsSubtitle')
    },
    {
        icon: Files,
        title: t('featuresFilesTitle'),
        subtitle: t('featuresFilesSubtitle')
    }
];

function Features() {
    const classes = useStyles();
    const { t } = useTranslation();

    function renderItem(item) {

        return (
            <Grid item xs={12} sm={6} md={4} key={item.title}>
                <Paper className={classes.itemContainer}>
                    <Paper className={classes.iconBackground}> {<item.icon className={classes.icon} />} </Paper>
                    <Box className={classes.textContainer}>
                        <Typography className={classes.title} variant="h5" noWrap>{item.title}</Typography>
                        <Typography className={classes.subtitle} variant="body1">{item.subtitle}</Typography>
                    </Box>
                </Paper>
            </Grid>
        );
    }

    return (
        <Box className={classes.gridContainer}>
            <Grid container spacing={3}>
                {features(t).map(renderItem)}
            </Grid>
        </Box>
    );
}

export default Features;
